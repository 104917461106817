import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PortableText } from '@portabletext/react';
import FadeIn from '../components/FadeIn';
import Seo from '../components/Seo';
import Blocks from '../components/Blocks';

export const query = graphql`
	query projectTemplateQuery($_id: String!) {
		page: sanityProject(_id: { eq: $_id }) {
			_id
			title
			seo {
				...seoFields
			}
			categories {
				title
			}
			mainImage {
				asset {
					gatsbyImageData(
						layout: FULL_WIDTH
						fit: FILLMAX
						placeholder: DOMINANT_COLOR
					)
				}
			}
			mainVideo {
				asset {
					url
					extension
				}
			}
			year
			role
			client
			_rawDescription
			_rawBlocks(resolveReferences: { maxDepth: 10 })
			blocks {
				_key
				_type
				_rawContent(resolveReferences: { maxDepth: 10 })
				content {
					...blocks
				}
				blockSettings {
					desktopWidth
				}
			}
		}
	}
`;

const pageTemplate = ({ data, pageContext }) => {
	const page = data && data.page;
	return (
		<>
			<Seo
				metaTitle={page.seo?.metaTitle || page.title}
				shareTitle={page.seo?.shareTitle || page.title}
				metaDesc={page.seo?.metaDesc}
				shareDesc={page.seo?.shareDesc}
			/>
			<div className="prose mb-8">
				<p className="uppercase text-xl">
					{page.categories.map((cat) => cat.title).join(' / ')}
				</p>
				<h1 className="h2">{page.title}</h1>
			</div>
			{page.mainImage && (
				<FadeIn>
					<div className="aspect-landscape relative overflow-hidden mb-8">
						{page.mainImage && (
							<GatsbyImage
								image={page.mainImage?.asset.gatsbyImageData}
								alt={page.title + ' project image'}
								className="object-cover h-full w-full"
							/>
						)}
						{page.mainVideo && (
							<video
								loop
								muted
								autoPlay
								playsInline
								className="absolute inset-0 h-full w-full object-cover"
							>
								<source
									src={page.mainVideo.asset.url}
									type={`video/${page.mainVideo.asset.extension}`}
								/>
							</video>
						)}
					</div>
				</FadeIn>
			)}

			<div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-8 mb-8">
				<div>
					<FadeIn>
						{page.client && (
							<div className="border-t border-black py-4 prose">
								<h2 className="h6">Client</h2>
								<p>{page.client}</p>
							</div>
						)}
						{page.year && (
							<div className="border-t border-black py-4 prose">
								<h2 className="h6">Year</h2>
								<p>{page.year}</p>
							</div>
						)}
						{page.role && !!page.role.length && (
							<div className="border-t border-black pt-4">
								<h2 className="h6">Role</h2>
								<ul className="text-base lg:text-xl">
									{page.role.map((role) => (
										<li>{role}</li>
									))}
								</ul>
							</div>
						)}
					</FadeIn>
				</div>
				<div className="md:col-span-3 lg:col-span-2 md:col-start-2 lg:col-start-3">
					<FadeIn>
						{page._rawDescription && (
							<div className="prose">
								<PortableText
									value={page._rawDescription || []}
								/>
							</div>
						)}
					</FadeIn>
				</div>
			</div>
			<Blocks blocks={page.blocks} _rawBlocks={page._rawBlocks} />

			{pageContext.next && (
				<section className="border-t border-black py-4 md:py-8 prose mt-4 lg:mt-8">
					<FadeIn>
						<h2 className="mb-8">
							<Link
								to={
									'/projects/' + pageContext.next.slug.current
								}
							>
								Next project - {pageContext.next.title}
							</Link>
						</h2>

						{pageContext.next.mainImage && (
							<Link
								to={
									'/projects/' + pageContext.next.slug.current
								}
								className={`group block aspect-landscape relative overflow-hidden bg-white`}
							>
								<div className="absolute inset-0 h-full w-full object-cover z-0">
									<GatsbyImage
										image={
											pageContext.next.mainImage.asset
												.gatsbyImageData
										}
										alt={
											pageContext.next.title +
											' project image'
										}
										className="absolute inset-0 h-full w-full object-cover "
									/>
								</div>
							</Link>
						)}
					</FadeIn>
				</section>
			)}
		</>
	);
};

export default pageTemplate;
